// initial state schema for appData
export const appDataInitialState = {
    bpConfig: {},
    clientDetails: {}
};

export function appDataReducer(state = appDataInitialState, action) {
    switch (action.type) {
        case 'SET_BPCONFIG': 
            return {
                ...state,
                bpConfig: action.payload ?? appDataInitialState.bpConfig
            }
        case 'UNSET_BPCONFIG': 
            return {
                ...state,
                bpConfig: appDataInitialState.bpConfig
            }
        
        case 'RESET_APP_DATA':
            return appDataInitialState;
        
        case 'SET_CLIENT_DETAILS': 
            return {
                ...state,
                clientDetails: action.payload
            }
        
        default:
            return state;
    }
}
