import React, { lazy } from "react";

const BusinessPlan = lazy(() => import("../screens/Main/DecisionSupport/BusinessPlan"));
const EPSEmergingPatterns = lazy(() => import("../components/organisms/EPSEmergingPatterns"));
const Capabilities = lazy(() => import("../screens/Master/Capabilities"));
const Home = lazy(() => import("../screens/Main/Home/Home"));
const AddClientMaster = lazy(() => import("../screens/Main/ClientMaster/AddClientMaster/AddClientMaster"));
const ListClientMaster = lazy(() => import("../screens/Main/ClientMaster/ListClientMaster/ListClientMaster"));
const UserManagement = lazy(() => import("../screens/Setup/UserManagement"));
const SetupUser = lazy(() => import("../screens/Setup/SetupUser"));

// Client Credentials Setup component
const ClientCredentials = lazy(() => import('../screens/Setup/ClientCredentials'));
const ClientSetupForm = lazy(() => import('../screens/Setup/ClientCredentials/SetupForm'));

// Master
const VisionVectors = lazy(() => import("../screens/Master/VisionVectors"));
const Objectives = lazy(() => import("../screens/Master/Objectives"));
const FundingStage = lazy(() => import("../screens/Master/FundingStage"));
const RevenueSource = lazy(() => import("../screens/Master/RevenueSource"));
const ProductService = lazy(() => import("../screens/Master/ProductService"));
const Expertise = lazy(() => import("../screens/Master/Expertise"));
const Deliverables = lazy(() => import("../screens/Master/Deliverables"));
const KeyActivity = lazy(() => import("../screens/Master/KeyActivity"));
const ProgramPhase = lazy(() => import("../screens/Master/ProgramPhase"));
const ProgramRole = lazy(() => import("../screens/Master/ProgramRole"));
const ReleaseAndPriorityList = lazy(() => import("../components/organisms/ReleaseAndPriorityList"));
const TestingPhase = lazy(() => import("../screens/Master/TestingPhase"));
const GeographicalLocation = lazy(() => import("../screens/Master/GeographicalLocation"));
const Infrastructure = lazy(() => import("../screens/Master/Infrastructure"));
const BplcStep = lazy(() => import("../screens/Master/BplcStep"));
const ExecutivePlanningSessions = lazy(() => import("../screens/Master/ExecutivePlanningSessions"));
const ResourceBand = lazy(() => import("../screens/Master/Resource/ResourceBand"));
const ResourceInfrastructure = lazy(() => import("../screens/Master/Resource/ResourceInfrastructure"));
const ResourceServiceItem = lazy(() => import("../screens/Master/Resource/ResourceServiceItem"));
const ResourcePeople = lazy(() => import("../screens/Master/Resource/ResourcePeople"));
const ResourceWorkStream = lazy(() => import("../screens/Master/Resource/ResourceWorkStream"));
const RoleEntitlements = lazy(() => import("../screens/Master/RoleEntitlements"));
const RoleSetup = lazy(() => import("../screens/Master/RoleEntitlements/RoleSetup"));
const RiskRating = lazy(() => import("../screens/Master/RiskRating"))
const Packages = lazy(() => import("../screens/Master/Packages"))
const ApprovalLadder = lazy(() => import("../screens/Master/ApprovalLadder"))
const TeamMeetings = lazy(() => import("../screens/Master/TeamMeetings"))
const CompanyWideAndGeographicLoadedCost = lazy(() => import("../screens/Master/CompanyWideAndGeographicLoadedCost"))
const Organization = lazy(() => import('../screens/Master/Organization'));
const Approval = lazy(() => import("../screens/Master/Approval"));
const BudgetItems = lazy(() => import("../screens/Master/BudgetItems"));
const ProgramPhaseNameList = lazy(() => import("../screens/Master/ProgramPhaseName"));
const SystemConfigurationsList = lazy(() => import("../components/organisms/SystemConfigurationsList"));

const EmailConfigurationList = lazy(() => import('../screens/Master/EmailConfiguration'));

// BP Form
const BpForm = lazy(() => import("../screens/Main/BpForm"));

//BP Initiative
const InitiativeList = lazy(() => import("../screens/Main/BpInitiative"));
const BPGrouping = lazy(() => import("../screens/Main/BpInitiative/BPGrouping"));
const InitiativeComparison = lazy(() => import("../screens/Main/BpInitiative/InitiativeComparison"));
const BpInitiativeEPS = lazy(() => import("../screens/Main/BpInitiative/EPS"));

//IFR
const IFRList = lazy(() => import("../screens/Main/BpInitiative"));
const BpInitiativeIFR = lazy(() => import("../screens/Main/BpInitiative/IFR"));

//Bp Configuration
const BpConfiguration = lazy(() => import("../screens/Main/BPConfiguration"));

// BP Rationalization
const BPRationalize = lazy(() => import('../screens/BPRationalize'));
const OmnibusRTB = lazy(() => import('../screens/Main/OmnibusRTB'));

// BP List
const BPList = lazy(() => import('../screens/Main/BPList'));

// Dashboard
const Dashboard = lazy(() => import("../screens/Main/Dashboard"));

// Planning Kickoff
const PlanningKickoff = lazy(() => import("../screens/PlanningKickoff"));

//new issue
const Issue = lazy(() => import("../screens/Main/IssueLogging"));

//issue log
const IssueLog = lazy(() => import("../screens/Main/IssueLogging/Log"));

//DecisionsActions
const DecisionsActions = lazy(() => import("../screens/Main/DecisionsActions/DecisionsActions"))

//BP Socialization
const IFRSocialization = lazy(() => import("../screens/Main/ExecutivePlanningSessions/IFRSocialization"));

// Help -> Glossary
const Glossary = lazy(() => import("../screens/Main/Help/Glossary/Glossary"));

// Help -> FAQs
const FAQs = lazy(() => import("../screens/Main/Help/FAQs/FAQs"));

// Help -> FAQs
const Information = lazy(() => import("../screens/Main/Help/Information/Information"));

//Help -> Guidance
const Guidance = lazy(() => import("../screens/Main/Help/Guidance"));

//Help -> Business Plan PDF
const BusinessPlanPDF =lazy(()=>import("../screens/Main/Help/BusinessPlanPdf"));

// L2a Estimating Model
const L2aEstimatingModel = lazy(() => import("../screens/Main/L2aModel/L2aEstimatingModel/L2aEstimatingModel"));

// L2b Estimating Model
const L2bEstimatingModel = lazy(() => import("../screens/Main/L2bModel"));

const DetailWorksheet = lazy(() => import("../screens/Main/L2aModel/DetailWorksheet/index"));
DetailWorksheet

// Rationlize IFRs
const RationalizeIFR = lazy(() => import("../screens/Main/ExecutivePlanningSessions/RationalizeIFR"));

// HRSystemIntegrationPage 
const HRSystemIntegrationPage = lazy(() => import("../screens/Main/HRSystemIntegrationPage/index"));

//Create Program
const CreateProgram = lazy(() => import('../screens/Main/Program/CreateProgram'));

//Program Structure
const ProgramStructure = lazy(() => import('../screens/Main/Program/ProgramStructure'));

//Budgey Decision Framework
const BudgetDecisionFramework = lazy(() => import('../screens/Main/BudgetDecisionFramework'));

const appNav = [
	{
		name: "Home",
		path: "home",
		element: <Home />,
	},
	{
		name: "AddClientMaster",
		path: "add-new-client-master",
		element: <AddClientMaster />,
	},
	{
		name: "ListClientMaster",
		path: "client-master-list",
		element: <ListClientMaster />,
	},
	{
		name: "Vision Vectors",
		path: "vision-vectors",
		element: <VisionVectors />,
	},
	{
		name: "Objectives",
		path: "objectives",
		element: <Objectives />,
	},
	{
		name: "Funding Stage",
		path: "funding-stage",
		element: <FundingStage />,
	},
	{
		name: "Revenue Sources",
		path: "revenue-sources",
		element: <RevenueSource />,
	},
	{
		name: "Products and Services",
		path: "product-service",
		element: <ProductService />,
	},
	{
		name: "Client Onboarding",
		path: "client-onboarding",
		element: <ClientCredentials />,
	},
	{
		name: "Client Onboarding",
		path: "client-onboarding/setup-client/:id",
		element: <ClientSetupForm />,
	},
	{
		name: "User Management",
		path: "user-management",
		element: <UserManagement />,
	},
	{
		name: "User Management",
		path: "user-management/setup",
		element: <SetupUser />,
	},
	{
		name: 'Organization',
		path: 'organization',
		element: <Organization />
	},
	{
		name: 'Expertise',
		path: 'proficiencies',
		element: <Expertise />
	},
	{
		name: 'Deliverables Grid',
		path: 'deliverables',
		element: <Deliverables />
	},
	{
		name: 'Key Activities',
		path: 'key-activities',
		element: <KeyActivity />
	},
	{
		name: 'Program Phase',
		path: 'activity-grid',
		element: <ProgramPhase />
	},
	{
		name: 'Program Role',
		path: 'program-role',
		element: <ProgramRole />
	},
	{
		name: 'Release',
		path: 'release',
		element: <ReleaseAndPriorityList />
	},
	{
		name: 'Testing Phases',
		path: 'testing-phases',
		element: <TestingPhase />
	},
	{
		name: 'BP Request Inbox',
		path: 'bp-request-inbox',
		element: <BpForm />
	},
	{
		name: 'Edit BP Request Inbox',
		path: 'bp-request-inbox/:id',
		element: <BpForm />
	},
	{
		name: 'Geographical Location',
		path: 'geographical-location',
		element: <GeographicalLocation />
	},
	{
		name: 'Infrastructure Components',
		path: 'infrastructure-components',
		element: <Infrastructure />
	},
	{
		name: 'BPLC Step',
		path: 'bplc-step',
		element: <BplcStep />
	},
	{
		name: 'Executive Planning Sessions',
		path: 'executive-planning-sessions',
		element: <ExecutivePlanningSessions />
	},
	{
		name: 'ResourceBand',
		path: 'resource-band',
		element: <ResourceBand />
	},
	{
		name: 'ResourceInfrastructure',
		path: 'resource-infrastructure',
		element: <ResourceInfrastructure />
	},
	{
		name: 'ResourceServiceItem',
		path: 'resource-service-item',
		element: <ResourceServiceItem />
	},
	{
		name: 'ResourcePeople',
		path: 'resource-people',
		element: <ResourcePeople />
	},
	{
		name: 'ResourceWorkStream',
		path: 'resource-work-stream',
		element: <ResourceWorkStream />
	},
	{
		name: "Initiative EPS",
		path: "bp-initiative-eps/:id",
		element: <BpInitiativeEPS />,
	},
	{
		name: "Initiative IFR",
		path: "bp-initiative-ifr/:id",
		element: <BpInitiativeIFR />,
	},
	{
		name: 'Initiative List',
		path: 'bp-initiative-list',
		element: <InitiativeList />
	},
	{
		name: 'IFR List',
		path: 'ifr-list',
		element: <IFRList />
	},
	{
		name: 'Initiative Comparison',
		path: 'bp-initiative-comparison',
		element: <InitiativeComparison />
	},
	{
		name: "BP Grouping",
		path: "bp-grouping",
		element: <BPGrouping />,
	},
	{
		name: 'Business Plan Configurations',
		path: 'business-plan-config',
		element: <BpConfiguration />
	},
	{
		name: 'BP Rationalization',
		path: 'bp-rationalization/:bpRequestId',
		element: <BPRationalize />
	},
	{
		name: 'Omnibus RTB',
		path: 'omnibus-rtb',
		element: <OmnibusRTB />
	},
	{
		name: 'BP Inbox',
		path: 'bp-inbox',
		element: <BPList />
	},
	{
		name: 'Roles',
		path: 'roles',
		element: <RoleEntitlements />
	},
	{
		name: 'Role EditCreate',
		path: 'roles/:action',
		element: <RoleSetup />
	},
	{
		name: 'Capabilities',
		path: 'capabilities',
		element: <Capabilities />
	},
	{
		name: 'Risk Rating',
		path: 'risk-rating',
		element: <RiskRating />
	},
	{
		name: 'Packages',
		path: 'packages',
		element: <Packages />
	},
	{
		name: 'Approval Ladder',
		path: 'approval-ladder',
		element: <ApprovalLadder />
	},
	{
		name: 'Team Meetings',
		path: 'team-meetings',
		element: <TeamMeetings />
	},
	{
		name: 'Loaded Cost',
		path: 'loaded-costs',
		element: <CompanyWideAndGeographicLoadedCost />
	},
	{
		name: 'Dashboard',
		path: 'eps-rationalize-initiatives',
		element: <Dashboard />
	},
	{
		name: 'Dashboard',
		path: 'rationalize-initiatives',
		element: <Dashboard />
	},
	{
		name: 'Planning Kickoff',
		path: 'planning-kickoff',
		element: <PlanningKickoff />
	},
	{
		name: 'Approval Ladder',
		path: 'approval-ladder',
		element: <Approval />
	},
	{
		name: 'Issue',
		path: 'issue',
		element: <Issue />
	},
	{
		name: 'Issue Log',
		path: 'issue-log',
		element: <IssueLog />
	},
	{
		name: "BP Socialization",
		path: "bp-socialization",
		element: <InitiativeList />
	},
	{
		name: "DecisionsActions",
		path: "decisions-actions",
		element: <DecisionsActions />
	},
	{
		name: "BudgetItems",
		path: "budget-items",
		element: <BudgetItems />
	},
	{
		name: "IFR Socialization",
		path: "ifr-socialization",
		element: <IFRSocialization />
	},
	{
		name: "Glossary",
		path: "glossary",
		element: <Glossary />
	},
	{
		name: "FAQs",
		path: "faqs",
		element: <FAQs />
	},
	{
		name: "Information",
		path: "information",
		element: <Information />
	},
	{
		name: "Rationalize Initiative Priorities",
		path: "rationalize-initiative-priorities",
		element: <EPSEmergingPatterns />
	},
	{
		name: "Guidance",
		path: "guidance",
		element: <Guidance />
	},
	{
		name: "L2aEstimatingModel",
		path: "bp-initiative-ifr/l2a-estimating-model/:ifrid",
		element: <L2aEstimatingModel />
	},
	{
		name: "L2aEstimatingModel",
		path: "bp-initiative-ifr/l2b-estimating-model/:ifrid",
		element: <L2bEstimatingModel />
	},
	{
		name: "DetailWorksheet",
		path: "bp-initiative-ifr/l2a-estimating-model/detail-worksheet/:ifrid",
		element: <DetailWorksheet />
	},
	{
		name: "Rationalize IFRs",
		path: "rationalize-ifr",
		element: <RationalizeIFR />
	},
	{
		name: "REC Analytics Dashboard",
		path: "rec-analytics-dashboard",
		element: <IFRSocialization />
	},
	{
		name: "HR System Integration Page",
		path: "hr-system-integration",
		element: <HRSystemIntegrationPage />
	},
	{
		name: "Program Phase Name List",
		path: "program-phase",
		element: <ProgramPhaseNameList />
	},
	{
		name: "Create Program",
		path: "create-program-structure",
		element: <CreateProgram />
	},
	{
		name: "Program Structure",
		path: "program-structure",
		element: <ProgramStructure />
	},
	{
		name: "Budget Decision Framework",
		path: "budget-decision-framework",
		element: <BudgetDecisionFramework />
	},
	{
		name: "Business Plan",
		path: "business-plan",
		element: <BusinessPlan />
	},
	{
		Name: "Email Configuration List",
		path: "email-config",
		element: <EmailConfigurationList />
	},
	{
		name: "Business Plan PDF",
		path: "business-plan-pdf",
		element: <BusinessPlanPDF />
	},
	{
		name: "System Configurations",
		path: "system-configurations",
		element: <SystemConfigurationsList />
	}
];

export default appNav;
