import React, { lazy } from 'react';
const Login = lazy(() => import('../screens/Auth/Login/Login'));
const SetNewPassword = lazy(() => import('../screens/Main/setNewPassword/setNewPassword'));

const appNav = [
	{
		name: 'Login',
		path: 'login',
		element: <Login />
	},
	{
		name: 'Reset Password',
		path: 'reset-password/:token',
		element: <Login />
	},
	{
		name: 'set New Password',
		path: 'set-new-password',
		element: <SetNewPassword />
	},
];


export default appNav;