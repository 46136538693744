import React, { lazy } from 'react';
import appNav from './appNav';
import { useNavigate } from 'react-router-dom';
import Loader from '../screens/Loader'
// import { useCookies } from 'react-cookie';
// react-redux
import { useSelector } from 'react-redux';

const RouteOutlet = lazy(() => import('./RouteOutlet'));

// eslint-disable-next-line react/prop-types
const PrivateRoute = ({ as: Component, ...props }) => {
	const [auth, setAuth] = React.useState(false);
	const navigate = useNavigate();
	// const [cookies] = useCookies();
	const userIsAuthenticated = useSelector(state => state.auth.isAuthenticated);
	React.useEffect(() => {
		if (!userIsAuthenticated) {
			navigate('/login');
			setAuth(false);
		} else {
			setAuth(true);
		}
		setAuth(true);
	}, [userIsAuthenticated, navigate]);
	return auth ? <Component {...props} /> : <Loader />;
};

const appRoutes = [
	{
		path: 'app',
		element: <PrivateRoute as={RouteOutlet} />,
		children: appNav,
	},
];

export default appRoutes;
