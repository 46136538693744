export const rolesReducer = (state = [], action) => {
    switch (action.type) {
        case 'SET_ROLES':
            return [...action.payload];
        
        case 'UNSET_ROLES':
            return [];
        
        default:
            return state;
    }
};
