import logo from '../assets/logo/Macroplan-Icon-Blue.svg';
const Loader = () => {
	return (
		<>	
			<img src={logo} className="App-logo" alt="logo" />
			<h1> Loading... </h1>
		</>
	);

}

export default Loader;