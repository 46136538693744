import { combineReducers } from "@reduxjs/toolkit";
// import { stateToStorage } from "../../utils/common";
import { authInitialState } from "../state";
// reducer slices
import { appDataReducer } from "./appData";
import { rolesReducer } from "./roles";
import { ifrListingReducer } from "./ifrListing";

const authReducer = (state = authInitialState, action) => {
    switch (action.type) {
        case 'SET_CURRENT_USER': 
            return { ...action.payload }
        
        case 'CLEAR_CURRENT_USER': 
            return { ...authInitialState }
        
        case 'SET_AUTH_ROLE': 
            return {
                ...state,
                role: action.payload
            }
        
        /*case 'OVERRIDE_WITH_CLIENT': {
            const newState = {
                ...state,
                ...action.payload
            };

            // will uncomment the following code when backend API is ready
            // stateToStorage(newState);

            return newState
        }*/
                
        default:
            return { ...state };
    }
};

export const rootReducer = combineReducers({
    auth: authReducer,
    appData: appDataReducer,
    roles: rolesReducer,
    ifrListing: ifrListingReducer
})