import React, { lazy } from 'react';
import authNav from './authNav';
import { useNavigate } from 'react-router-dom';
import Loader from '../screens/Loader'
// import { useCookies } from 'react-cookie';
// react-redux
import { useSelector } from 'react-redux';

const RouteOutlet = lazy(() => import('./RouteOutlet'));

// eslint-disable-next-line react/prop-types
const PrivateRoute = ({ as: Component, ...props }) => {
	const [componentLoaded, setComponentLoaded] = React.useState(false);
	const navigate = useNavigate();
	// const [cookies] = useCookies();
	const userIsAuthenticated = useSelector(state => state.auth.isAuthenticated);

	const userIsFirstLogin = useSelector(state => state.auth.isFirstLogin);

	React.useEffect(() => {
		if (userIsAuthenticated) {
			if (userIsFirstLogin == 0) {
				// if new user created navigate to set new password page
				navigate('/set-new-password');
			} else {
				navigate('/app/home');
			}
			setComponentLoaded(false);
		} else if (window.location.pathname === '/') {
			navigate('/login');
			setComponentLoaded(false);
		} else {
			setComponentLoaded(true);
		}
		setComponentLoaded(true);
	}, [userIsAuthenticated, navigate]);
	return componentLoaded ? <Component {...props} /> : <Loader />;
};

const authRoutes = [
	{
		path: '/',
		element: <PrivateRoute as={RouteOutlet} />,
		children: authNav,
	},
];

export default authRoutes;
