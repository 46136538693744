export const BASE_URL = "https://macroplanfrontend.estonetech.in/";
export const BACKEND_BASE = process.env.REACT_APP_BACKEND_BASE_URL;
export const API_BASE_URL = BACKEND_BASE + "api/";
export const API_RESPONSE_HAS_ERROR = false;
export const SNACKBAR_TIMEOUT = 5000;
export const TYPE_ERROR = "error";
export const TYPE_SUCCESS = "success";
export const NOTE_CATEGORY = {
    REQUEST: 'bpRequest',
    COMBINED: 'bpCombined',
    RELATED: 'bpReletedto'
};
export const CMD_KEYS = ['Alt', 'Control', 'AltGraph', 'Escape', 'Shift', 'Delete', 'End', 'PageDown', 'PageUp', 'Home', 'Insert'];
export const ARROW_KEYS = ['ArrowUp', 'ArrowLeft', 'ArrowRight', 'ArrowUp'];
export const INVALID_DATA_EXCEL = 'Invalid Data Format in Excel';
export const APP_DATE_FORMAT = "MMM-dd-yyyy";
export const ADMIN_ROLES = ["Super Admin", "Super User", "Admin"];
export const SERVER_ERROR_CODE = [422, 500];
export const SERVER_ERROR_MSG = "Internal Server Error. Please contact admin.";

// for remove "e", "E", "+", "-" from input type - number
export const IGNORE_NUM_INPUT = ["e", "E", "+", "-"];

// Email Regex
export const EMAIL_REGX_WITHOUT_DOMAIN = /^[a-zA-Z]+[a-zA-Z_\-.0-9]*$/g;
export const EMAIL_REGX = /^[a-zA-Z]+[a-zA-Z_\-.0-9]+@[a-z0-9-]+(?:\.[a-z]{2,15}){1,2}$/g;

// Image mime types
export const IMAGE_TYPES = ['jpeg', 'jpg', 'png', 'svg', 'ico', 'gif'];

export const REF_TABLES_HEIGHT = screen.height >= 1080 ? '80vh' : (screen.height >= 900 ? '72vh' : (screen.height >= 750 ? '62vh' : '50vh'));

export const bplcStepsStatus = {
    COMPLETED: 'completed',
    INITIATED: 'initiated',
    MISSED: 'missed'
};

// colours representing bplc steps status
export const bplcStatusColours = {
    completed: '#45e865',
    initiated: '#ffbf00',
    missed: '#ef0000',
}

// status colors for approvals
export const approvalStatusColors = {
    completed: '#45e865',
    initiated: '#ffbf00',
    pending: '#ddd'
}

// BPLC schematics step names
export const bplcSchematicNames = [
    {
        step: 1,
        name: 'Definition and Setup'
    },
    {
        step: 2,
        name: 'Request BPs'
    },
    {
        step: 3,
        name: 'Convert in-flight initiatives'
    },
    {
        step: 4,
        name: 'Compile candidate initiatives'
    },
    {
        step: 5,
        name: 'Compile RTB Budget'
    },
    {
        step: 6,
        name: 'Prepare IFRs'
    },
    {
        step: 7,
        name: 'Rationalize initiatives'
    },
    {
        step: 8,
        name: 'Confirm PFAs'
    },
    {
        step: 9,
        name: 'Address resource constraints'
    },
    {
        step: 10,
        name: 'Finalize business plan'
    },
];

// a collection of random colors for charts
export const randomColors = [
    '#f799a4',
    '#f97780',
    '#ffdda8',
    '#f9b6f2',
    '#cbfc85',
    '#96ffeb',
    '#c4e7ff',
    '#c7fcab',
    '#e6c4fc',
    '#91ffcb',
    '#ed8c6f',
    '#caff7c',
    '#95f9ab',
    '#fc97df',
    '#bbfca1',
    '#85a3ea',
    '#f4d7a8',
    '#ff87bd',
    '#e6f492',
    '#d781ef',
    '#709fe5',
    '#b2ffea',
    '#a5f977',
    '#ffccdc',
    '#91f48d',
    '#fcbfd2',
    '#d0f282'
];

export const REF_TABLE_ROWS = [5, 10, 20, 25];


export const FILTERS = [
    {
        label: "Initiative",
        value: "initiative",
        apiSlug: "id",
    },
    {
        label: "Initiative Sponsor",
        value: "initiative-sponsor",
        apiSlug: "sponsor",
    },
    {
        label: "Vision Vector",
        value: "vision-vector",
        apiSlug: "visionAlignment",
    },
    {
        label: "Objective",
        value: "objective",
        apiSlug: "primaryObjective",
    },
    {
        label: "Funding Stage",
        value: "funding-stage",
        apiSlug: "stage",
    },
    {
        label: "Department",
        value: "department",
        apiSlug: "department",
    },
    {
        label: "Program",
        value: "program",
        apiSlug: "program",
    },
];
