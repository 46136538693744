
export const ifrListingState = {
    isIFRListing: false,
};

export const ifrListingReducer = (state = ifrListingState, action) => {
    switch (action.type) {
        case 'SET_IFRLISTING':
            return {
                ...state,
                isIFRListing: true,
            };
        
        case 'UNSET_IFRLISTING':
            return {
                ...state,
                isIFRListing: false
            };
        
        default:
            return state;
    }
};
