import { configureStore } from "@reduxjs/toolkit";

// import root reducer and state
import { rootReducer } from "./reducers";
import { rootState } from "./state";

// helper function to create a store
export const createStore = () => {

    return configureStore({
        reducer: rootReducer,
        preloadedState: rootState,
    });
}