// Utilities
import { storageToState } from "../../utils/common"
// initial state schema
import { appDataInitialState } from "../reducers/appData";
import { ifrListingState } from "../reducers/ifrListing";

// initial state schema for authenticated user
export const authInitialState = {
    '_id': '',
    name: '',
    email: '',
    mobileNumber: '',
    isActive: 0,
    isDeleted: 0,
    isAuthenticated: false,
    role: null
}

// initial state schema for roles
const roles = [];

// get auth user details from local storage
const user = storageToState();

export const rootState = {
    auth: user ?? authInitialState,
    appData: appDataInitialState,
    roles: roles,
    ifrListing: ifrListingState,
}