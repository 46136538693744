/**
 * Common utility functions that can be used throughout the application
 */

// Module imports

// Constants
import { bplcStepsStatus, bplcStatusColours } from '../config/constants';

// Sweet alert
import Swal from 'sweetalert2';
import { SNACKBAR_TIMEOUT, TYPE_SUCCESS } from '../config/constants';

// Date-FNS
import { differenceInDays } from 'date-fns';

/**
 * @param {object} state - redux state
 * @description stores redux auth user state in local storage 
 */
export const stateToStorage = (state) => {
    window.localStorage.setItem('user', JSON.stringify(state))
}

/**
 * @returns auth user from storage
 */
export const storageToState = () => {
    let stateStr = window.localStorage.getItem('user');
    try
    {
        stateStr = JSON.parse(stateStr);
        if (stateStr && stateStr?._id != null)
        {
            return stateStr;
        }
        return null;
    }
    catch($e)
    {
        return null;
    }
}

export const removeAuthUserFromStorage = () =>  {
    window.localStorage.removeItem('user');
}

/**
 * @param {} obj - display children state of navbar component 
 */
export const storeDisplayChildrenToSession = obj => {
    window.sessionStorage.setItem('displayChildren', JSON.stringify(obj));
}

export const getDisplayChildrenFromSession = () => {
    if (window.sessionStorage.getItem('displayChildren')) {
        return JSON.parse(window.sessionStorage.getItem('displayChildren'))
    }
    return {};
}

/**
 * @description - debounces passed callback with sepcified delay
 * @param func {function} - a callback that is executed 
 * @param delay  {number} - delay in number of milliseconds
 * @returns closure which invokes subject after the delayed time
 */
export const debounce = (func, delay = 300) => {
    let timer = null;

    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, delay);
    }
};

/**
 * @description - Displays a toast message for a duration 
 * equal to SNACKBAR_TIMEOUT
 * @param {Object} {type: string, title: string}
 * @returns void 
 */
export const showToastMsg = ({
    type = TYPE_SUCCESS,
    title = ''
}) => {
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: SNACKBAR_TIMEOUT,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    Toast.fire({
        icon: type,
        title
    })
}

/**
 * @description - sets & returns colour based on bplc status
 * @param {*} bplc - A BPLC step with its status 
 * @returns { string } colour - the colour respresenting bplc step
 */
export const getBplcStepColour = (bplc) => {
    // default colour (not started)
    let colour = '#ddd';
    // change the colour based on bplc status for each step
    if (bplc.status === bplcStepsStatus.COMPLETED) {
        colour = bplcStatusColours[bplcStepsStatus.COMPLETED];
    }

    if (bplc.status === bplcStepsStatus.INITIATED) {
        colour = bplcStatusColours[bplcStepsStatus.INITIATED];
    }
    
    if (bplc.status !== bplcStepsStatus.COMPLETED && diffIsLessThanDay(bplc.date)) {
        colour = bplcStatusColours[bplcStepsStatus.MISSED];
    }

    return colour;
}

// check whether difference between bplc date and current date is less than a day or not
export const diffIsLessThanDay = (bplcDate) => {
    return differenceInDays(new Date(bplcDate), new Date()) < 1;
}

/**
 * @description Converts number to USD currency
 * @param {*} value - A numeric value
 * @returns A string for the value represented in USD
 */
export const toUsd = (value) => {
    const currencyFormatter = Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 });
    return currencyFormatter.format(value);
}

/**
 * @description Rounds off values in an array
 * @param {*} arr - A numeric array
 * @returns { object } array - Values rounded off to the nearest integer
 */
export const roundedArray = (arr) => {
    return arr.map(item => Math.round(item));
}

/**
 * @description - Number.prototype.toFixed(), but for arrays
 * @param {*} arr - A numeric array
 * @param {*} decimalCount - Desired number of decimal places
 * @returns { object } array - With values fixed to desired number of decimal places
 */
export const toFixedArray = (arr, decimalCount = 2) => {
    return arr.map(item => item.toFixed(decimalCount));
}

/**
 * 
 * @param {*} value - A numeric value
 * @returns { string } - Value in US numeric form
 */
export const toUsNum = (value) => {
    const formatter = Intl.NumberFormat('en-US', { maximumFractionDigits: 0 });
    return formatter.format(value);
}

/**
 * 
 * @param {*} arr - An array of string values
 * @returns { object } An array of values formatted with sentence case
 */
export const toSentenceCaseArray = (arr) => {
    return arr.map(item => item[0].toUpperCase() + item.slice(1))
}

export const calculateFte = (effortWorkdays = null, targetDate = null, plannedStartDate = null, daysPerMonth = null, workdaysPerMonth = null) => {
    if (effortWorkdays && targetDate && plannedStartDate && daysPerMonth && workdaysPerMonth) {
        return parseFloat((effortWorkdays / ((differenceInDays(new Date(targetDate), new Date(plannedStartDate)) + 1) / parseInt(daysPerMonth) * parseInt(workdaysPerMonth))).toFixed(2));
    } else return null;
}