import './App.css';
import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppRoute from './routes/index';
import Loader from './screens/Loader';

function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <Suspense fallback={<Loader />}>
          <AppRoute />
        </Suspense>
        {/*Suspense is a component that wraps the custom components 
          and enables them to communicate to React that they’re waiting 
          for some data to load before the component is rendered.*/}
      </BrowserRouter>
    </div>
  );
}

export default App;
