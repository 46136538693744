import React from 'react';
import { useRoutes } from 'react-router-dom';
import appRoutes from './appRoutes';
import authRoutes from './authRoutes';
import NotFound from '../screens/NotFound';


const Route = () => {
  const element = useRoutes([ 
    ...appRoutes,
    ...authRoutes,
    { path: '*', element: <NotFound /> },
  ]);

  return element;
};

Route.propTypes = {};

export default Route;
